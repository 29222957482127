import { Component, OnInit } from "@angular/core";

import { ActivatedRoute, Router } from "@angular/router";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { AppConfig } from "../../../appConfig/app.config";
import { ApiServicesService } from "../../../Services/api-services.service";
import { AlertService } from "../../common/Alert/alert/alert.services";
@Component({
  selector: "app-editsponsors",
  templateUrl: "./editsponsors.component.html",
  styleUrls: ["./editsponsors.component.scss"],
})
export class EditsponsorsComponent implements OnInit {
  submitted = false;
  IsLoading = false;
  sponsoType = [
    { id: 1, Name: "Platinum" },
    { id: 2, Name: "Gold" },
    { id: 3, Name: "Community " },
    { id: 4, Name: "Learning" },
    { id: 5, Name: "Media" },
    { id: 6, Name: "Security" },
    { id: 7, Name: "Hackathon" },
    { id: 8, Name: "DigitalBadge" },
    { id: 9, Name: "Silver" },
    { id: 10, Name: "Support" }
  ];
  userid: any;
  sponserEditForm: UntypedFormGroup;
  user_avatar;
  photo;
  formData = new FormData();
  constructor(
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private appConfig: AppConfig,
    private apiCall: ApiServicesService,
    private router: Router,
    private alert: AlertService
  ) {}

  ngOnInit(): void {
    this.userid = this.route.snapshot.paramMap.get("sponserid");
    this.getSponser();

    //formControl
    this.sponserEditForm = this.formBuilder.group({
      id: new UntypedFormControl(""),
      name: new UntypedFormControl("", Validators.required),
      twitterUrl: new UntypedFormControl(""),
      linkedinUrl: new UntypedFormControl(""),
      logoPath: new UntypedFormControl(""),
      SponsorType: new UntypedFormControl(null, Validators.required),
      location: new UntypedFormControl(""),
      websiteUrl: new UntypedFormControl("", Validators.required),
      isActive: new UntypedFormControl(true),
      createdBy: new UntypedFormControl(localStorage.getItem("UserId")),
      eventYear: new UntypedFormControl(this.appConfig.GetEventYear),
      createdDate: new UntypedFormControl(new Date()),
    });
  }
  get sponsors() {
    return this.sponserEditForm.controls;
  }

  getSponser() {
    this.IsLoading = true;
    this.apiCall.GetSponserById(this.userid).subscribe((res) => {
      this.sponserEditForm.get("id").setValue(res.id);
      this.sponserEditForm.get("name").setValue(res.name);
      this.sponserEditForm.get("twitterUrl").setValue(res.twitterUrl);
      this.sponserEditForm.get("linkedinUrl").setValue(res.linkedInUrl);
      this.sponserEditForm.get("SponsorType").setValue(res.sponsorType);
      this.sponserEditForm.get("websiteUrl").setValue(res.websiteUrl);
      this.sponserEditForm.get("logoPath").setValue(res.logoPath);
      this.sponserEditForm
        .get("createdBy")
        .setValue(localStorage.getItem("UserId"));
      this.sponserEditForm
        .get("eventYear")
        .setValue(this.appConfig.GetEventYear);
      this.sponserEditForm.get("createdDate").setValue(new Date());
      this.user_avatar = res.logoPath;
      this.IsLoading = false;
    });
    this.IsLoading = false;
  }

  upatesponsors() {
    if (this.sponserEditForm.invalid) {
      return;
    }
    this.IsLoading = true;
    this.formData.append(
      "SponsorForm",
      JSON.stringify(this.sponserEditForm.value)
    );
    this.apiCall.updatesponser(this.formData).subscribe((res) => {
      if (res != null || res != undefined) {
        this.IsLoading = false;
        this.alert.success("Sponser Updated!");
        this.router.navigate(["/ListSponsors"]);
      } else {
        (error) => {
          this.alert.error(error);
        };
        this.IsLoading = false;
      }
    });
  }

  sponserImage(image: FileList) {
    const img = image[0].name.split(".").pop();
    if (
      img === "png" ||
      img === "PNG" ||
      img === "jpg" ||
      img === "JPG" ||
      img === "jpeg" ||
      img === "gif" ||
      img === "psd" ||
      img === "bmp"
    ) {
      for (let _files in image) {
        if (_files != "length" && _files != "item") {
          this.photo = image.item(Number(_files));

          this.formData.append("photo", this.photo);
          this.formData.append("isImageUpdated", "true");
        }
      }
    } else {
    }
  }
}
