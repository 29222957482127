<app-az-header> </app-az-header>
<app-alert></app-alert>

<app-az-menu></app-az-menu>
<app-loader [Loader]="IsLoading"></app-loader>
<!-- Main content -->
<div class="content-wrapper">
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 offset-md-3">
          <div class="card card-primary">
            <div class="card-header">
              <h3 class="card-title">Add Sponsors</h3>
            </div>
            <form
            [formGroup]="sponsorsForm" (ngSubmit)="addsponsors()"
            >
              <div class="card-body">
                <div class="form-group">
                  <label>Sponsor Name</label>
                  <input type="text" formControlName="name" id="Sponsorname"
                        [ngClass]="{ 'is-invalid': submitted && sponsors.name.errors }" autocomplete="off"
                        class="form-control" placeholder="Sponsor Name">
                      <div *ngIf="submitted && sponsors.name.errors" class="invalid-feedback">
                        <div *ngIf="sponsors.name.errors.required">Sponsor name is required</div>
                      </div>
                </div>
                <div class="form-group">
                  <label>Select Type</label>
                  <select
                    class="form-control select2"
                    formControlName="SponsorType"
                  >
                    <option value="" disabled>Select Type</option>

                    <option *ngFor="let data of sponsoType" [value]="data.id">
                      {{ data.Name }}
                    </option>
                  </select>
                </div>



              <div class="form-group">
                <label>Website Url</label>
                <input type="text" formControlName="websiteUrl" id="websiteUrl"
                [ngClass]="{ 'is-invalid': submitted && sponsors.websiteUrl.errors }" autocomplete="off"
                class="form-control" placeholder="Website URL">
              <div *ngIf="submitted && sponsors.websiteUrl.errors" class="invalid-feedback">
                <div *ngIf="sponsors.websiteUrl.errors.required">Website is required</div>
              </div>
            </div>



              <div class="form-group">
                <label>Twitter</label>
                <input type="text" formControlName="twitterUrl" id="twitter" autocomplete="off"
                class="form-control" placeholder="Twitter URL">
              </div>

              <div class="form-group">
                <label>Linked In</label>
                <input type="text" formControlName="linkedinUrl" id="linkedin" autocomplete="off"
                        class="form-control" placeholder="Linkedin URL">
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label  >Sponser Logo Image</label>
                    <div class="input-group">
                      <div class="custom-file">
                        <input type="file"  (change)="sponsorLogo($event.target.files)" accept="image/x-png,image/gif,image/jpeg"
                         id="image">
                        <label for="image"
                          class="custom-file-label">Choose file</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <img  class="avatar-user avatar img"
                  src="{{user_avatar}}">
                </div>
              </div>


            </div>



              <div class="card-footer">
                <span class="float-right">

                  <button type="submit" (click)="reset()" class="btn btn-danger ml-2">
                    Reset
                  </button>
                  <button
                    type="submit"
                    [disabled]="buttonEnable!=true"
                    class="btn btn-success"
                  >
                    Update
                  </button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<!-- </div> -->
<!-- <app-az-footer></app-az-footer> -->
