import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiServicesService } from '../../../Services/api-services.service';

import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
@Component({
  selector: 'app-attendeeslist',
  templateUrl: './paid-attendees-list.component.html',
  styleUrls: ['./paid-attendees-list.component.scss']
})
export class PaidAttendeesListComponent implements OnInit {

  pageSizes = [15,30,45,70]
  displayedColumns: string[] = ['S.No','Name','Mobile Number','Email','Amount','Date'];

  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator,{static: true}) paginator: MatPaginator;
  constructor(
    private apiCall:ApiServicesService
  ) {

  }

  ngOnInit(): void {
    this.getAttendess()
  }


getAttendess(){
this.apiCall.getPaidAttendees().subscribe(attendess=>{


  this.dataSource = new MatTableDataSource();
  this.dataSource.data =attendess;
  this.dataSource.paginator = this.paginator;
})
}
attendeesFilter(event:Event){
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();

  if (this.dataSource.paginator) {
    this.dataSource.paginator.firstPage();
  }
}
}
